(function () {'use strict';}());

angular.module('app')
  .controller('AppController', function ($scope,$timeout,$interval,$window,AppService) {
  	var trace = angular.trace;
    var interval = null, timer = null, time = 0;
    var backbtn = true;

    $scope.PAGE_HOME = 1;
    $scope.PAGE_QUIZ = 2;
  	$scope.PAGE_SIMULATION = 3;
    $scope.PAGE_GAMES = 4;
    $scope.PAGE_WELCOME = 5;
    $scope.PAGE_OUTRO = 6;
    $scope.PAGE_SIMULATION_INTRO = 7;
    $scope.PAGE_ABOUT_YOU = 8;
    $scope.PAGE_SKILLS = 9;
    $scope.PAGE_ACCOUNT = 10;
    $scope.PAGE_WELCOME_2 = 11;
    $scope.PAGE_USER_SUMMARY = 12;
    $scope.PAGE_PASSWORD_RECOVERY = 13;
    $scope.PAGE_SETTINGS = 14;

    $scope.lastPage = null;
  	$scope.currentPage = null;
    $scope.ready = false;
    $scope.showFooter = true;
    $scope.currentActivity = null;
    $scope.currentExitCallback = null;
    $scope.spinnerSource = null;
    $scope.recommendedScenario = null;
    $scope.authenticated = false;
    $scope.saving = false;
    $scope.currentAlert = null;
    $scope.randomName = null;
    $scope.language = window.localStorage.getItem('language') || 'en';
    $scope.languageFull = window.localStorage.getItem('languageFull') || 'English';
    $scope.skippedQuiz = false;

    $scope.playAudio = null;
    $scope.stopAudio = null;

    $scope.userInfo = {username: null, password: null, confirm: null, name: null, birthdate: null, question: 'What is your favorite color?'};
    $scope.economy = {coping:0,communication:0,"decision-making":0,"myth-busting":0};
    $scope.currentAudioInfo = {volume: window.localStorage.getItem('sound')} || 1;
    $scope.currentAutoVO = {volume: window.localStorage.getItem('autoVO')} || 0;//|| {volume: false};

    $window.onfocus = startTimer;
    $window.onblur = stopTimer;

    $scope.$on("removeBackBtn",removeBackBtn);

    $window.onSignIn = function (user) {
      // console.log('sogg in', user);
      googleSignIn(user);
    };


    $window.googleRender = function () {
      $window.gapi.signin2.render('google-signin', {
        'scope': 'profile email',
        'width': 240,
        'height': 50,
        'longtitle': true,
        'theme': 'dark',
        'onsuccess': onSignIn,
        'onfailure': onFailure
      });

      var notSignedIn = document.querySelector("#google-signin [id^='not_signed_in']");
      // console.log('g signin title', notSignedIn);
      notSignedIn.textContent = "Google Classroom";
    };

    angular.element(function () {
      console.log('page loading completed');
    });

    authenticate();

    function onFailure () {

    }

  	$scope.setCurrentPage = function(value) {
      $scope.$emit("stopAudio");
      if(value == $scope.PAGE_HOME) 
      {
        $scope.autoPlayedQuiz23Stem = true; //if passed personality quiz, checklist autoplays as expected
        backbtn = true;
      }
      if(value == $scope.PAGE_GAMES)
      {
        $scope.onGameMenu = true;
      }
      $scope.lastPage = $scope.currentPage;
  		$scope.currentPage = value;
      $scope.setShowFooter(true);
      $scope.$emit("closeLoops");
      $(window).scrollTop(0);

      ga('send', 'pageview', $scope.getPageClassName(value));
  	};

    $scope.setPlayAudio = function(callback,loopCallback,stop) {
      $scope.playAudio = callback;
      $scope.playAudioLoop = loopCallback;
      $scope.stopAudio = stop;
    };

    $scope.setCurrentAlert = function(text) {
      $scope.currentAlert = text;
    };

    $scope.updateSpinnerSource = function() {
      $scope.$broadcast("spinnerSourceUpdate",$scope.spinnerSource);
    };

    $scope.setCurrentActivity = function(value) {
      $scope.currentActivity = value;
      if(!value) return;

      if(value.type === "quiz") $scope.setCurrentPage($scope.PAGE_QUIZ);
      else if(value.type === "game") {
        $scope.requestedGame = value.value;
        $scope.setCurrentPage($scope.PAGE_GAMES);
      }
    };

    $scope.unsetRequestedGame = function() {
      $scope.requestedGame = null;
    };

    $scope.setShowFooter = function(value) {
      $scope.showFooter = value;
    };

    $scope.updateState = function() {
      $scope.$emit("currentPageStateChange");
    };

  	$scope.getPageClassName = function(value) {
  		for(var prop in $scope) {
  			if(!prop.match(/PAGE_/gim)) continue;
  			if($scope[prop] !== value) continue;
  			return prop.toLowerCase().replace(/page_/gim,"");
  		}
  		return null;
  	};

    $scope.setCurrentExitCallback = function(callback) {
      $scope.currentExitCallback = callback;
    };

    $scope.startApp = function() {
      var fq = getFirstQuiz();
      if(fq) {
        $scope.setCurrentPage($scope.PAGE_QUIZ);
        $scope.setCurrentActivity({value:fq.id,type:"quiz"});
      } else {
        $scope.setCurrentPage($scope.PAGE_HOME);
      }
      $scope.updateState();
    };

    $scope.skip = function() {
      $scope.skippedQuiz = true;
      var questions = [{"questionId": 86, "optionId": 319}, {"questionId": 87, "optionId": 199}, {"questionId": 88, "optionId": 88}, {"questionId": 89, "optionId": 89}, {"questionId": 90, "optionId": 202}, {"questionId": 91, "optionId": 203}, {"questionId": 92, "optionId": 92}, {"questionId": 93, "optionId": "205,322,432,448,455,462,474"}, {"questionId": 94, "optionId": 323}, {"questionId": 95, "optionId": 207}, {"questionId": 96, "optionId": 325}, {"questionId": 97, "optionId": 407}, {"questionId": 98, "optionId": 408}, {"questionId": 99, "optionId": 328}, {"questionId": 100, "optionId": 212}, {"questionId": 101, "optionId": 330}, {"questionId": 102, "optionId": 214}, {"questionId": 103, "optionId": 332}, {"questionId": 104, "optionId": 216}, {"questionId": 105, "optionId": 334}];
      AppService.skip($scope.userInfo.id, questions).then(function(){
        $scope.quizzes = AppService.quizzes;
        checkForRecommendedScenario(null);
        $scope.setCurrentPage($scope.PAGE_HOME);
      });
    };

    $scope.register = function() {
      $scope.userInfo.username = $scope.randomName;
      return AppService.register($scope.userInfo).then(onRegister);
    };

    $scope.login = function() {
      return AppService.login($scope.userInfo).then(onLogin);
    };

    $scope.loginAsGuest = function($event) {
      $event.preventDefault();
      $event.stopImmediatePropagation();
      if($scope.language == 'en')
      {
        $scope.userInfo.username = "guest";
      }
      else{
        $scope.userInfo.username = "invitado";
      }
      return AppService.login($scope.userInfo).then(onLogin);
    };

    $scope.getUsernameHeader = function() {
      if($scope.userInfo.username == "guest" || $scope.userInfo.username == "invitado"){
        if($scope.language == 'en')
        {
          return "guest";
        }
        else{
          return "invitado";
        }
      }
      else if($scope.userInfo.username == "teacher" || $scope.userInfo.username == "maestro")
      {
        if($scope.language == 'en')
        {
          return "teacher";
        }
        else{
          return "maestro";
        }
      }
      else{
        return $scope.userInfo.username;
      }
    };

    function googleSignIn(response) {
      var responsePayload = jwt_decode(response.credential);
      console.log('sogg in', response, responsePayload);

      $scope.userInfo.username = responsePayload.name;

      var id_token = responsePayload.sub;

      return AppService.googleAuth(response.credential, $scope.userInfo).then(onLogin);
    }


    $scope.logout = function() {
      return AppService.logout().then(onLogout);
    };

    // $scope.logout = function() {
    //   var auth2 = gapi.auth2.getAuthInstance();
    //   if (auth2.isSignedIn.get()) {
    //     auth2.signOut().then(function () {
    //       auth2.disconnect().then(function () {
    //         return AppService.logout().then(onLogout);
    //       });
    //     });
    //   } else {
    //     return AppService.logout().then(onLogout);
    //   }
    // };

    $scope.exit = function() {
      var backwards = [$scope.PAGE_ABOUT_YOU];

      if(!$scope.currentExitCallback) {
        $scope.updateState();
        $scope.setCurrentPage(backwards.indexOf($scope.currentPage) === -1 ? $scope.PAGE_HOME : $scope.lastPage);
        return;
      }
      $scope.currentExitCallback();
      $scope.currentExitCallback = null;
      $scope.updateState();
    };

    $scope.showExitButton = function() {
      if(backbtn === false){
        return false;
      }
      if($scope.currentActivity && $scope.currentActivity.type === "quiz" && $scope.currentActivity.value >= 21) return false;
      if($scope.currentPage === $scope.PAGE_GAMES ||
        $scope.currentPage === $scope.PAGE_QUIZ ||
        $scope.currentPage === $scope.PAGE_SIMULATION ||
        $scope.currentPage === $scope.PAGE_SKILLS ||
        $scope.currentPage === $scope.PAGE_ABOUT_YOU ||
        $scope.currentPage === $scope.PAGE_SETTINGS ||
        $scope.currentPage === $scope.PAGE_SIMULATION_INTRO) return true;
      return false;
    };

    $scope.setRecommendedScenario = function(scenario) {
      $scope.recommendedScenario = scenario;
    };

    $scope.setSaving = function(value) {
      if(interval) {
        $timeout.cancel(interval);
      }

      $scope.saving = value;

      if($scope.saving === true) {
        interval = $timeout(alertUser, 5000);
      }
    };

    function alertUser() {
      alert("We are trying to save your data, but it is taking longer than expected. If the screen continues to be unresponsive, we suggest refreshing your browser and trying again.");
    }

    $scope.setShowingMenu = function(value) {
      $scope.showingMenu = value;
    };

    $scope.setShowEndOfGame = function(value) {
      $scope.setShowEndOfGame = value;
    };

    $scope.getSecurityQuestionForUser = function() {
      return AppService.getSecurityQuestionForUser($scope.userInfo.recoveryUsername);
    };

    $scope.resetUserPassword = function(uid,password) {
      AppService.resetUserPassword($scope.userInfo.recoveryUsername,password).then(onResetPassword);
    };

    $scope.setResumePage = function() {
      var fq = getFirstQuiz();
      $scope.updateState();
      $scope.setCurrentPage(fq ? $scope.PAGE_WELCOME : $scope.PAGE_HOME);
      checkForRecommendedScenario(fq);
    };

    function onResetPassword(response) {

      if(!response.data.authenticated) {
        $scope.randomName = AppService.randomName;
        $scope.setCurrentPage($scope.PAGE_ACCOUNT);
      } else {
        $scope.authenticated = true;
        $scope.userInfo.password = $scope.userInfo.recoveryPassword;
        initialize($scope.PAGE_USER_SUMMARY);
      }
    }

    function authenticate() {
      AppService.authenticate().then(onAuthentication);
    }

    function onAuthentication(response) {
      $scope.textLookup = AppService.textLookup;

      if(!response.data.authenticated) {
        $scope.randomName = AppService.randomName;
        $scope.setCurrentPage($scope.PAGE_ACCOUNT);
      } else {
        $scope.authenticated = true;
        initialize();
      }
    }

  	function initialize(requestedPage) {
      var password = $scope.userInfo.password;
      // console.log('initialize', requestedPage);

      $scope.authenticated = true;
      $scope.ready = true;

      $scope.userInfo = AppService.userInfo;
      $scope.quizzes = AppService.quizzes;
      $scope.economy = AppService.economy;
      $scope.games = AppService.games;
      $scope.userInfo.password = password;

      $scope.getText = getText;

      $scope.scenarios = AppService.simulations;
      var fq = getFirstQuiz();

      if(window.GET.page) {
        $scope.setCurrentPage($scope["PAGE_"+window.GET.page.toUpperCase()]);
        checkForRecommendedScenario(fq);
      }
      else {
        $scope.updateState();
        $scope.setCurrentPage(fq ? (requestedPage ? requestedPage : $scope.PAGE_WELCOME) : $scope.PAGE_HOME);
        checkForRecommendedScenario(fq);
      }

      if(window.GET.game) {
        $scope.requestedGame = parseInt(window.GET.game);
        $scope.setCurrentPage($scope.PAGE_GAMES);
      }

      $scope.spinnerSource = AppService.spinner;
      $scope.brainSimStandard = AppService.brain_sim_standard;
      $scope.brainSimReskin = AppService.brain_sim_reskin;
      startTimer();
  	}

    function getText(key) {
      key = key.trim().toLowerCase();
      if ($scope.textLookup.hasOwnProperty(key)) {
        return $scope.textLookup[key][$scope.language];
      } else {
        console.warn('cannot find', key, 'in textLookup', $scope.textLookup);
        return key;
      }
    }

    function removeBackBtn() {
      backbtn = false;
    }

    $scope.setLanguage = function(value) {
      var langLookup = {
        'English': 'en',
        'Español': 'es'
      };
      if (value && langLookup[value]) {
        $scope.language = langLookup[value];
      }
      AppService.language = $scope.language;
      $scope.languageFull = value;
      window.localStorage.setItem('language', $scope.language);
      window.localStorage.setItem('languageFull', $scope.languageFull);
      $scope.updateState();
      // console.log('updated language to', $scope.language, value, $scope.languageFull);
    };

    $scope.setSound = function(value, onLogout) {
      if((value == 1 && document.getElementById('sound').checked === false) && onLogout === false)
      {
        value = 0;
      }
      $scope.currentAudioInfo.volume = value;
      window.localStorage.setItem('sound', value);
    };

    $scope.toggleSoundSwitch = function(){
      if($scope.currentAudioInfo.volume == 1)
      {
        document.getElementById('sound').checked = true;
      }
    };

    $scope.setAutoVO = function(value) {
      if(value == 1 && document.getElementById('vo').checked === false)
      {
        value = 0;
      }
      $scope.currentAutoVO.volume = value;
      window.localStorage.setItem('autoVO', value);
    };

    $scope.toggleVOSwitch = function(){
      if($scope.currentAutoVO.volume == 1)
      {
        document.getElementById('vo').checked = true;
      }
    };


    $scope.checkLanguage = function() {
      console.log('language is', $scope.language);
    };

    function onRegister(response) {
      if(response.data.authenticated === true) initialize($scope.PAGE_USER_SUMMARY);
      return response;
    }

    function onLogin(response) {
      $scope.currentAudioInfo.volume = 1;
      if (response.data.authenticated === true) {
        initialize();
      } else {
        console.error('could not authenticate', response);
      }
      return response;
    }
    function onLogout(response) {
      $scope.authenticated = false;
      window.location.reload();
    }

    function getFirstQuiz() {
      var remaining = AppService.quizzes.filter(filterGTKY);
      if(!remaining.length) return null;
      return remaining[0];
    }

    function checkForRecommendedScenario(fq) {
      if(fq) return;

      var reference = fetchQuiz(22);
      if(reference.questions[reference.questions.length - 1].feedback.presented)
      {
        var feedback = reference.questions[reference.questions.length - 1].feedback.presented['text_' + $scope.language];
        var introvert = feedback ? feedback.match(/^introvert/i) : false;
        var extrovert = feedback ? feedback.match(/^extrovert/i) : false;

        if(introvert) {
        if(!AppService.simulations[2].complete) $scope.setRecommendedScenario(AppService.simulations[2]);
      } else if(extrovert) {
        if(!AppService.simulations[0].complete) $scope.setRecommendedScenario(AppService.simulations[0]);
      } else {
        if(!AppService.simulations[1].complete) $scope.setRecommendedScenario(AppService.simulations[1]);
      }

      }
      // else {
      //   console.log(reference.questions[reference.questions.length - 1]);
      // }

    }

    $scope.alertSettings = function(){
      var alertText = "";

      if($scope.language == 'en')
      {
        alertText = "Visit the Settings button to personalize your audio settings. You can turn sounds and automatic narration on or off.";
      }
      else{
        alertText = "Visite el botón Configuración para personalizar su configuración de audio. Puede activar o desactivar los sonidos y la narración automática."; 
      }
      $scope.setCurrentAlert(alertText);
    };

    function filterGTKY(e) {
      return e.tier === 0 && e.complete === false;
    }

    $scope.fetchQuiz = function(value) {
      return fetchQuiz(value);
    };

    function fetchQuiz(value) {
      for(var i = 0, count = $scope.quizzes.length; i < count; i++) {
        var quiz = $scope.quizzes[i];
        if(quiz.id === value) return quiz;
      }
      return null;
    }

    function startTimer() {
      $interval.cancel(timer);
      timer = $interval(incrementTime,1000);
    }
    function incrementTime() {
      time ++;
      if(time === 10) {
        time = 0;
        AppService.updateTime($scope.userInfo.id);
      }
    }

    function stopTimer() {
      $interval.cancel(timer);
    }


////////////////////////////////////////////////////////////////////////////////
// FROM JWT DECODE /////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
    var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

    function InvalidCharacterError(message) {
        this.message = message;
    }

    InvalidCharacterError.prototype = new Error();
    InvalidCharacterError.prototype.name = "InvalidCharacterError";

    function polyfill(input) {
        var str = String(input).replace(/=+$/, "");
        if (str.length % 4 == 1) {
            throw new InvalidCharacterError(
                "'atob' failed: The string to be decoded is not correctly encoded."
            );
        }
        for (
            // initialize result and counters
            var bc = 0, bs, buffer, idx = 0, output = "";
            // get next character
            (buffer = str.charAt(idx++));
            // character found in table? initialize bit storage and add its ascii value;
            ~buffer &&
            ((bs = bc % 4 ? bs * 64 + buffer : buffer),
                // and if not first of each 4 characters,
                // convert the first 8 bits to one ascii character
                bc++ % 4) ?
            (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6)))) :
            0
        ) {
            // try to find character in table (0-63, not found => -1)
            buffer = chars.indexOf(buffer);
        }
        return output;
    }

    var atob = (typeof window !== "undefined" &&
        window.atob &&
        window.atob.bind(window)) ||
    polyfill;

    function b64DecodeUnicode(str) {
        return decodeURIComponent(
            atob(str).replace(/(.)/g, function(m, p) {
                var code = p.charCodeAt(0).toString(16).toUpperCase();
                if (code.length < 2) {
                    code = "0" + code;
                }
                return "%" + code;
            })
        );
    }

    function base64_url_decode(str) {
        var output = str.replace(/-/g, "+").replace(/_/g, "/");
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += "==";
                break;
            case 3:
                output += "=";
                break;
            default:
                throw "Illegal base64url string!";
        }

        try {
            return b64DecodeUnicode(output);
        } catch (err) {
            return atob(output);
        }
    }

    function InvalidTokenError(message) {
        this.message = message;
    }

    InvalidTokenError.prototype = new Error();
    InvalidTokenError.prototype.name = "InvalidTokenError";

    function jwtDecode(token, options) {
        if (typeof token !== "string") {
            throw new InvalidTokenError("Invalid token specified");
        }

        options = options || {};
        var pos = options.header === true ? 0 : 1;
        try {
            return JSON.parse(base64_url_decode(token.split(".")[pos]));
        } catch (e) {
            throw new InvalidTokenError("Invalid token specified: " + e.message);
        }
    }

    /*
     * Expose the function on the window object
     */

    //use amd or just through the window object.
    if (window) {
        if (typeof window.define == "function" && window.define.amd) {
            window.define("jwt_decode", function() {
                return jwtDecode;
            });
        } else if (window) {
            window.jwt_decode = jwtDecode;
        }
    }

  });
