(function () {'use strict';}());
angular.module('app').service('AppService', function($http)
{
	var trace = angular.trace;
	var self = {};

	self.authenticate = function() {
		return $http.post("php/services/ProjectHereService.php",{request: "authenticate"}).then(onAuthentication);
	};

	self.login = function(info) {
		return $http.post("php/services/ProjectHereService.php",{request: "login", username: info.username, password: info.password}).then(onAuthentication);
	};

	self.logout = function() {
		return $http.post("php/services/ProjectHereService.php",{request: "logout"});
	};

	self.register = function(info) {
		return $http.post("php/services/ProjectHereService.php",{request: "register", username: info.username, password: info.password, birthdate: info.birthdate, name: info.name, question: info.question, answer: info.answer}).then(onAuthentication);
	};

	self.saveQuizResponse = function(uid,qid,oid,oids,economy) {
		return $http.post("php/services/ProjectHereService.php",{request: "saveQuizResponse", userId: uid, questionId: qid, optionId: oids ? oids.join(",") : oid, economy: economy});
	};

	self.saveSimulationResponse = function(uid,qid,oid,economy) {
		return $http.post("php/services/ProjectHereService.php",{request: "saveSimulationResponse", userId: uid, simulationId: qid, optionId: oid, economy: economy});
	};

	self.saveEconomy = function(uid,economy) {
		return $http.post("php/services/ProjectHereService.php",{request: "updateEconomy", userId: uid, economy: economy});
	};

	self.saveGameResult = function(uid, gid, complete, economy) {
		return $http.post("php/services/ProjectHereService.php",{request: "saveGameResult", userId: uid, gameId: gid, complete: complete, economy: economy});
	};

	self.saveFunFact = function(uid, fid) {
		return $http.post("php/services/ProjectHereService.php",{request: "saveFunFact", userId: uid, funfactId: fid});
	};

	self.getSecurityQuestionForUser = function(username) {
		return $http.post("php/services/ProjectHereService.php",{request: "getSecurityQuestionForUser", username: username});
	};

	self.resetUserPassword = function(username, password) {
		return $http.post("php/services/ProjectHereService.php",{request: "resetUserPassword", username: username, password: password}).then(onAuthentication);
	};

	self.skip = function(uid, questions) {
		return $http.post("php/services/ProjectHereService.php",{request: "skip", userId: uid, questions: questions}).then(onSkip);
	};

	self.updateTime = function(uid) {
		return $http.post("php/services/ProjectHereService.php",{request: "updateTime", userId: uid}).then(onPing);
	};

  self.googleAuth = function(id_token, info) {
    return $http.post("php/services/ProjectHereService.php", {request: "googleAuthenticate", 'idtoken': id_token, username: info.username}).then(onAuthentication);
  };

  self.language = 'en';

	function onAuthentication(response) {
    // console.log('response data',response);
    // console.log('response data',response.data);
		self.userInfo = response.data.userInfo;
		self.quizzes = response.data.quizzes;
		self.simulations = response.data.simulations;
		self.economy = response.data.economy;
		self.spinner = response.data.spinner;
		self.slider = response.data.slider;
    self.brain_sim_standard = response.data.brain_sim_standard;
    self.brain_sim_reskin = response.data.brain_sim_reskin;
		self.games = response.data.games;
		self.gameResults = response.data.gameResults;
		self.funfacts = response.data.funfacts;
		if(response.data.generatedUsername) self.randomName = response.data.generatedUsername;

    self.textLookup = self.textLookup || {};
    if (response.data.textLookup) {
      for (var i = 0; i < response.data.textLookup.length; i++) {
        var entry = response.data.textLookup[i];
        self.textLookup[entry.key] = entry;
      }
    } //else {
    //   console.error('cannot get text lookup table', response);
    // }


    // console.log('textLookup', self.textLookup);


		if(self.quizzes) synthesizeQuizzes();
		if(self.simulations) synthesizeSimulations();
		if(self.games) synthesizeGames();
		if(self.spinner) {
			self.spinner = self.spinner.concat(self.funfacts);
			synthesizeSpinner();
		}

		if(window.GET.autocomplete) {
			for(var prop in self.economy) {
				self.economy[prop] += 100;
			}
		}

		//trace("auth",self);
		return response;
	}

  function googleLoginButton() {
    var id_token = googleUser.getAuthResponse().id_token;
  }

  // $scope.onSignIn = function(googleUser) {

  //   var id_token = googleUser.getAuthResponse().id_token;
  //   // var xhr = new XMLHttpRequest();
  //   // xhr.open('POST', 'php/services/ProjectHereService.php');
  //   // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  //   // xhr.onload = function() {
  //   //   console.log('Signed in as: ' + xhr.responseText);
  //   // };
  //   // xhr.send('idtoken=' + id_token);
  //   console.log('signed in, token', id_token);
  //   $http.post("php/services/ProjectHereService.php", {request: "googleAuthenticate", 'idtoken': id_token}).then(onAuthentication);
  // }

	function onSkip(response) {
		self.quizzes = response.data.quizzes;
		synthesizeQuizzes();
	}

	function onPing(result) {
		if(result.data && result.data.authenticated === true) return result;
		if(!self.userInfo) {
			return result;
		}
		alert("Your session has expired. Please login again.");
		window.location.reload();
	}

	function synthesizeQuizzes() {

		for(var i = 0, count = self.quizzes.length; i < count; i++) {
			var quiz = self.quizzes[i];
			var questions = quiz.questions;

			for(var j = 0, qcount = questions.length; j < qcount; j++) {
				var question = questions[j];

				if(question.type === "Checklist" && question.selection) {
					question.selections = getSelectionsForQuestion(question);
					delete question.selection;
				}
				else if(question.selection) {
					question.selection = getSelectionForQuestion(question);
				}

				if(quiz.complete && question.feedback.type === "conditional") {
					getConditionalFeedback(question,quiz);
				}
			}

			if(window.GET.autocomplete) quiz.complete = true;
		}
	}

	function getSelectionForQuestion(question) {
		var options = question.options;
		for(var i = 0, count = options.length; i < count; i++) {
			var option = options[i];
			if(option.id === parseInt(question.selection)) return option;
		}
		return null;
	}

	function getSelectionsForQuestion(question) {
		var options = question.options;
		var selections = question.selection.split(",");
		var output = {};

		for(var i = 0, count = selections.length; i < count; i++) {
			var option = selections[i];
			output[parseInt(option)] = true;
		}
		return output;
	}

	function synthesizeSimulations() {

		for(var i = 0, count = self.simulations.length; i < count; i++) {
			var simulation = self.simulations[i];
			var options = simulation.options;

			if(simulation.selection) simulation.complete = true;

			for(var j = 0, ocount = options.length; j < ocount; j++) {
				var option = options[j];
				var cost = option.cost.split(",");
				var skills = option.icon ? option.icon.split(",") : [];
				option.cost = cost.map(convertCost).filter(filterZeroes);
				option.skill = skills.map(convertSkill);

				if(option.id === simulation.selection) simulation.score = option.score;
			}

			if(window.GET.autocomplete) {
				simulation.complete = true;
				simulation.score = 3;
			}
		}

		if(window.GET.autocomplete && window.GET.eog) {
			self.simulations[0].complete = false;
			self.simulations[0].score = null;
		}

	}

	function convertCost(e) {
		return parseInt(e);
	}

	function convertSkill(e) {
		return e.replace(/\-/gim," ");
	}

	function filterZeroes(e) {
		if(e === 0) return false;
		return true;
	}

    function getConditionalFeedback(question,quiz) {
      var conditions = question.feedback.conditions;
      // console.log('feedback', question.feedback.conditions);
      var selections = question.selections;

      if(question.type !== "Checklist" && !selections) return getConditionalFeedbackMC(quiz,question);

      var x = 0;

      for(var prop in selections) {
        if(selections[prop] === true) x++;
      }

      for(var i = 0, count = conditions.length; i < count; i++) {
        var condition = conditions[i];

        /* for now, we need to use eval to execute the conditional feedback for quiz items where the logic
        is defined in the import spreadsheet. new Function approach also fails jslint */

        /*jslint evil: true */
        var satisfied = eval(condition.rule);
        if(satisfied === true) {
          question.feedback.presented = condition;
          return condition['text_' + self.language];
        }
      }
      return null;
    }

    function getConditionalFeedbackMC(quiz,q) {
      var conditions = q.feedback.conditions;
      // console.log('feedback', q.feedback.conditions);
      var questions = quiz.questions;
      var x = 0,i,count;


      for(i = 0, count = questions.length; i < count; i++) {
        var question = questions[i];
        var selection = question.selection;
        if(selection.value) x+= selection.value;
      }

      for(i = 0, count = conditions.length; i < count; i++) {
        var condition = conditions[i];
        /*jslint evil: true */
        var satisfied = eval(condition.rule);
        if(satisfied === true) {
          q.feedback.presented = condition;
          return condition['text_' + self.language];
        }
      }
      return null;
    }

    function synthesizeSpinner() {
    	self.spinner.forEach(function(item){
    		var reference;
    		switch(item.type) {
    			case "quiz":
    				reference = fetchItem(item.value,self.quizzes);
    				item.complete = reference.complete;
    			break;
    			case "game":
    				item.complete = self.gameResults[item.value];
    				item.source = fetchItem(item.value,self.games);
    			break;
    			default:
    		}
    	});
    }

    function synthesizeGames() {
    	var games = self.games;

    	for(var i = 0, count = games.length; i < count; i++) {
    		var game = games[i];
    		if(window.GET.autocomplete === "true") game.complete = true;
    	}
    }

    function fetchItem(value,array) {
    	for(var i = 0, count = array.length; i < count; i++) {
    		if(array[i].id === value) return array[i];
    	}
    	return null;
    }


	return self;
});